import {
  type PayloadAction,
  type Slice,
  type SliceCaseReducers,
  createSlice,
} from '@reduxjs/toolkit';
import type { MapViewState } from 'deck.gl';

import { useAppSelector } from '../hooks.ts';
import type { LayerType } from './layers/LayersList.ts';

type DrawingMode =
  | 'translate'
  | 'modify'
  | 'select'
  | 'polygon'
  | 'measure'
  | 'clear';

type Map = {
  viewState: MapViewState;
  mapStyle: string;
  drawingMode: DrawingMode;
  selectedLayers: LayerType[];
};

const initialViewState: MapViewState = {
  bearing: 0,
  latitude: 39.446888593720935,
  longitude: -95.65494071225665,
  maxPitch: 60,
  maxZoom: 18,
  minPitch: 0,
  minZoom: 0,
  pitch: 0,
  zoom: 3.4944034428445687,
};

const initialState: Map = {
  viewState: initialViewState,
  mapStyle: 'mapbox://styles/mapbox/dark-v9',
  drawingMode: 'select',
  selectedLayers: ['control-point-network-layer', 'Primary', 'Local'],
};

export const mapSlice: Slice<Map, SliceCaseReducers<Map>, 'map'> = createSlice({
  name: 'map',
  initialState: initialState,
  reducers: {
    setViewState: (state, action: PayloadAction<MapViewState>) => {
      state.viewState = { ...state.viewState, ...action.payload };
    },
    setDrawingMode: (state, action: PayloadAction<DrawingMode>) => {
      state.drawingMode = action.payload;
    },
    setSelectedLayers: (state, action: PayloadAction<LayerType[]>) => {
      state.selectedLayers = action.payload;
    },
  },
});

export const { setSelectedLayers, setViewState, setDrawingMode } =
  mapSlice.actions;

export const useMap = (): Map => {
  const map = useAppSelector(state => state.map);

  return map;
};

export default mapSlice.reducer;
