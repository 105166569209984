import { createAction, createSlice } from '@reduxjs/toolkit';
import control from 'db/control';
import { UUID } from 'io-ts-types';
import { ControlPointAssociation } from 'usmart-common';

import { getDB } from '../db/index.ts';

export type ControlPointsState = {
  error: string | undefined;
  associations: Array<ControlPointAssociation>;
  lastPt: ControlPointAssociation | undefined;
};

const initialState: ControlPointsState = {
  error: undefined,
  lastPt: undefined,
  associations: [],
};

const db = getDB();

export const addRequest = createAction<ControlPointAssociation>(
  'associations/add/request'
);
export const addSuccess = createAction<ControlPointAssociation>(
  'associations/add/success'
);
export const addFailure = createAction<Error>('associations/add/failure');

export const updateRequest = createAction<ControlPointAssociation>(
  'associations/update/request'
);
export const updateSuccess = createAction<ControlPointAssociation>(
  'associations/update/success'
);
export const updateFailure = createAction<Error>('associations/update/failure');

export const removeRequest = createAction<ControlPointAssociation>(
  'associations/remove/request'
);
export const removeSuccess = createAction('associations/remove/success');
export const removeFailure = createAction<Error>('associations/remove/failure');

export const getAll = async () =>
  await db.aev
    .where('key')
    .startsWith('localId')
    .toArray()
    .then(recs =>
      control(db).getAll<ControlPointAssociation>(
        recs.map(rec => rec.docId as UUID)
      )
    );

export const controlPointsSlice = createSlice({
  name: 'control_points',
  initialState,
  reducers: {
    empty: state => {
      state.associations = [];
    },
    update: (state, action) => {
      state.error = action.payload.error;
    },
    remove: (state, action) => {
      state.error = action.payload.error;
    },
  },
});

export const actions = {
  addRequest,
  addFailure,
  addSuccess,
  updateRequest,
  updateFailure,
  updateSuccess,
  removeRequest,
  removeFailure,
  removeSuccess,
  empty: controlPointsSlice.actions.empty,
};
export default controlPointsSlice.reducer;
