import { serverEndpoint } from 'api';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import useFetch from 'use-http';
import { NgsLlhData } from 'usmart-common';

import type { AppDispatch, RootAppState } from './store.ts';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootAppState> = useSelector;

export type NgsLlhOptions = {
  latitude: number | string;
  longitude: number | string;
  eht?: number | string;
  inDatum?: string;
  outDatum?: string;
};

export const useNgsConversion = ({
  longitude,
  latitude,
  eht = 100.0,
  inDatum = 'nad83(2011)',
  outDatum = 'nad83(2011)',
}: NgsLlhOptions) => {
  if (inDatum === 'nad83') {
    inDatum = 'nad83(1986)';
  }

  if (outDatum === 'nad83') {
    outDatum = 'nad83(1986)';
  }

  if (inDatum === 'wgs84' || outDatum === 'wgs84') {
    inDatum = 'nad83(2011)';
    outDatum = 'nad83(2011)';
  }

  if (eht === '') {
    eht = 100.0;
  }

  const { loading, data, error } = useFetch<NgsLlhData>(
    `${serverEndpoint}/api/ngs/ncat?lat=${latitude}&lon=${longitude}&eht=${eht}&inDatum=${inDatum}&outDatum=${outDatum}`,
    [longitude, latitude]
  );

  return {
    data,
    loading,
    error,
  };
};
