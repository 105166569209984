import { createAction, createSlice } from '@reduxjs/toolkit';
import { AllExtendables } from 'api';
import blob from 'db/blob';
import control from 'db/control';
import { Blob } from 'db/types';
import { UUID } from 'io-ts-types';
import { ControlPointSafe, Document } from 'usmart-common';

import { getDB } from '../db/index.ts';

export type ControlPointsState = {
  error: string | undefined;
  points: Array<ControlPointSafe>;
  lastPt: ControlPointSafe | undefined;
};

const initialState: ControlPointsState = {
  error: undefined,
  lastPt: undefined,
  points: [],
};

const db = getDB();

export const versionsRequest = createAction<string>('control/versions/request');
export const versionsSuccess = createAction<string>('control/versions/success');
export const versionsFailure = createAction<string>('control/versions/failure');

export const addRequest = createAction<ControlPointSafe>('control/add/request');
export const addSuccess = createAction<ControlPointSafe>('control/add/success');
export const addFailure = createAction<Error>('control/add/failure');

// string represents control point ID
export const approveRequest = createAction<UUID>('control/approve/request');
export const approveSuccess = createAction<ControlPointSafe>(
  'control/approve/success'
);
export const approveFailure = createAction<Error>('control/approve/failure');

// string represents control point ID
export const rejectRequest = createAction<UUID>('control/reject/request');
export const rejectSuccess = createAction<ControlPointSafe>(
  'control/reject/success'
);
export const rejectFailure = createAction<Error>('control/reject/failure');
export type Upload = {
  document: Document;
  blob: Blob;
  controlPointId: UUID;
  index: number;
};
export const addFileRequest = createAction<Upload>('control/addfile/request');
export const addFileSuccess = createAction('control/addfile/success');
export const addFileFailure = createAction<Error>('control/addfile/failure');

export const removeFileRequest = createAction<UUID>(
  'control/removefile/request'
);
export const removeFileSuccess = createAction('control/removefile/success');
export const removeFileFailure = createAction<Error>(
  'control/removefile/failure'
);

export const updateRequest = createAction<ControlPointSafe>(
  'control/update/request'
);
export const updateSuccess = createAction<ControlPointSafe>(
  'control/update/success'
);
export const updateFailure = createAction<Error>('control/update/failure');

export const removeRequest = createAction<UUID>('control/remove/request');
export const removeSuccess = createAction('control/remove/success');
export const removeFailure = createAction<Error>('control/remove/failure');

type Filter = {
  field: string;
  val: string;
};

type Query = {
  filter: Filter;
};

const table = () => db.doc;

export const parseQuery = (s: string): Query => ({
  filter: [s].map(clause => {
    const parts = clause.split('=');
    return {
      field: parts[0],
      val: parts[1],
    } as Filter;
  })[0],
});

export const get = control(db).read;
export const getMany = control(db).readMany;
export const fetch = table().limit;
export const query = async (q: Query) =>
  await control(db).findEntities(q.filter.field, q.filter.val);
export const count = async () => await db.doc.count();
export const queryStr = async (s: string) => await query(parseQuery(s));
export const getImage = blob(db).get;

const EXTENDABLES_ID = 'b8369c56-01fb-11ed-b939-0242ac120002' as UUID;
export const getExtendablesCache = (): Promise<AllExtendables> =>
  control(db)
    .get(EXTENDABLES_ID)
    .then(d => {
      if (d?.success && d.data) {
        return d.data;
      } else {
        return d?.error;
      }
    });
export const putExtendablesCache = (fields: AllExtendables) =>
  control(db).put(EXTENDABLES_ID, fields);

export const controlPointsSlice = createSlice({
  name: 'control_points',
  initialState,
  reducers: {
    empty: state => {
      state.points = [];
    },
    update: (state, action) => {
      state.error = action.payload.error;
    },
    remove: (state, action) => {
      state.error = action.payload.error;
    },
  },
});

export const actions = {
  versionsRequest,
  versionsFailure,
  versionsSuccess,
  addRequest,
  addFailure,
  addSuccess,
  addFileRequest,
  addFileSuccess,
  addFileFailure,
  removeFileRequest,
  removeFileSuccess,
  removeFileFailure,
  updateRequest,
  updateFailure,
  updateSuccess,
  removeRequest,
  removeFailure,
  removeSuccess,
  approveFailure,
  approveRequest,
  approveSuccess,
  rejectFailure,
  rejectRequest,
  rejectSuccess,
  empty: controlPointsSlice.actions.empty,
};
export default controlPointsSlice.reducer;
