import {
  Action,
  AnyAction,
  combineReducers,
  configureStore,
  createImmutableStateInvariantMiddleware,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { routerMiddleware } from 'connected-react-router/immutable';
import { createBrowserHistory } from 'history';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import thunk from 'redux-thunk';

import syncClient from './api/index.ts';
import associationsReducer from './associations/slice.ts';
import authReducer from './auth/slice.ts';
import reportReducer from './control/slice.ts';
import blobDB from './db/blob.ts';
import docsDB from './db/control.ts';
import { getDB } from './db/index.ts';
import queueDB from './db/queue.ts';
import rootEpic from './epics.ts';
import mapReducer from './map/slice.ts';
import notificationReducer from './notifications/slice.ts';
import syncReducer from './sync/slice.ts';

export const history = createBrowserHistory();
const initialState = {};

const appReducer = combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  associations: associationsReducer,
  controlPoints: reportReducer,
  map: mapReducer,
  notifications: notificationReducer,
  sync: syncReducer,
});

// allow for hard resets of application state
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: any, action: AnyAction) => {
  if (action.type === 'HARD_RESET') return appReducer(undefined, action);
  return appReducer(state, action);
};

const db = getDB();
const queue = queueDB(db);
const docs = docsDB(db);
const blobs = blobDB(db);

export type Dependencies = {
  queue: typeof queue;
  docs: typeof docs;
  blobs: typeof blobs;
  db: typeof db;
  syncClient: typeof syncClient;
};
const epicMiddleware = createEpicMiddleware<Action, Action, RootAppState>({
  dependencies: { queue, docs, db, syncClient, blobs },
});

const logger = createLogger({
  predicate: (getState, action) => !action.type.includes('map/setViewState'),
});

const store = configureStore({
  reducer,
  preloadedState: initialState,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    thunk,
    routerMiddleware(history),
    createImmutableStateInvariantMiddleware(),
    logger,
    epicMiddleware,
  ],
});

epicMiddleware.run(rootEpic);

export type RootAppState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;

export default store;
