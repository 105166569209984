import { Action } from '@reduxjs/toolkit';
import { UUID } from 'io-ts-types';
import { isNil } from 'ramda';
import { StateObservable, combineEpics } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  map,
  mapTo,
  mergeMap,
} from 'rxjs/operators';
import { client } from 'usmart-common';

import { type SyncError, actions } from './slice.ts';
import { QueueOp } from '../db/types.ts';
import { Dependencies, RootAppState } from '../store.ts';

const UNABLE_TO_REACH_SERVER = '1';
const NO_POINTS_TO_SYNC = '2';

export const clearQueueEpic = (
  action$: Observable<Action>,
  _: StateObservable<RootAppState>,
  { queue }: Dependencies
) =>
  action$.pipe(
    filter(actions.clearQueue.match),
    mergeMap(() => from(queue.removeAll())),
    mergeMap(() => of(actions.queueCleared()))
  );

const uploadDocument = async (
  deps: Dependencies,
  s: QueueOp,
  controlPointId: UUID
) => {
  const doc = await deps.docs.get(s.docId);
  const blob = await deps.blobs.get(s.docId);
  if (blob && doc?.data) {
    const d = doc.data;
    const b = new Blob([blob?.data]);
    const f = new File([b], d.name);
    const req = client.document.createDocument(f, {
      name: d.name,
      id: d.id,
      type: d.type,
      controlPointId,
      mimeType: d.mimeType,
    });
    const result = await deps.syncClient.mutate(req);
    if (result.errors) {
      throw new Error('Got Errors!');
    }
    return result;
  }
};

export const popQueueEpic = (
  action$: Observable<Action>,
  state: StateObservable<RootAppState>,
  deps: Dependencies
) =>
  action$.pipe(
    filter(actions.resume.match),
    filter(() => state.value.sync.serverReachable),
    mergeMap(() => from(deps.queue.peek())),
    mergeMap(s => {
      switch (s?.apiOp) {
        case 'createControlPoint':
          return from(deps.docs.read(s.docId)).pipe(
            mergeMap(async cp => {
              if (isNil(cp)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return from({} as any);
              }
              const newPt = await deps.syncClient.createControlPoint(cp);
              const prefix = `${cp.id}-doc`;
              const docsToSync = await deps.queue.getAllWithPrefix(prefix);
              for (const d of docsToSync) {
                const res = await uploadDocument(deps, d, newPt.id);
                if (res?.data) {
                  await deps.queue.remove(d.key);
                  await deps.docs.remove(d.docId, false);
                }
              }
              await deps.docs.remove(s.docId);
              return of(newPt);
            }),
            mapTo(s.key),
            catchError(e => handleError(e, s.key, s.id, s.apiOp))
          );
        case 'updateControlPoint':
          return from(deps.docs.read(s.docId)).pipe(
            mergeMap(async cp => {
              if (isNil(cp)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return from({} as any);
              }
              const newPt = await deps.syncClient.updateControlPoint(cp);
              const prefix = `${cp.id}-doc`;
              const docsToSync = await deps.queue.getAllWithPrefix(prefix);
              for (const d of docsToSync) {
                const res = await uploadDocument(deps, d, newPt.id);
                if (res?.data) {
                  await deps.queue.remove(d.key);
                  await deps.docs.remove(d.docId, false);
                }
              }
              await deps.docs.remove(s.docId);
              return of(newPt);
            }),
            mapTo(s.key),
            catchError(e => handleError(e, s.key, s.id, s.apiOp))
          );
        default:
          return of(NO_POINTS_TO_SYNC);
      }
    }),
    mergeMap((s: string | SyncError) => {
      if (
        s === UNABLE_TO_REACH_SERVER ||
        s === NO_POINTS_TO_SYNC ||
        (typeof s === 'object' && Object.hasOwn(s, 'error'))
      ) {
        return of(s);
      } else {
        return deps.queue.remove(s as string);
      }
    }),
    mergeMap(a => {
      if (a === UNABLE_TO_REACH_SERVER) {
        return of(actions.unreachable(), actions.updateUnsyncCountRequest());
      } else if (a === NO_POINTS_TO_SYNC) {
        return of(actions.pause(), actions.updateUnsyncCountRequest());
      } else if (typeof a === 'object' && Object.hasOwn(a, 'error')) {
        console.error({ 'Sync Error': a });
        return of(
          actions.pushError(a as SyncError),
          actions.pause(),
          actions.updateUnsyncCountRequest()
        );
      } else {
        return of(actions.resume(), actions.updateUnsyncCountRequest());
      }
    })
  );

const handleError = (
  error: Error,
  key: string,
  id: string,
  apiOp: string
): Observable<SyncError> =>
  of({ error: error.message, id, key, apiOp, stack: error.stack });

export const checkServerEpic = (
  action$: Observable<Action>,
  _: StateObservable<RootAppState>,
  { syncClient }: Dependencies
) =>
  action$.pipe(
    filter(actions.unreachable.match),
    mergeMap(() => of(true).pipe(delay(10000))),
    mergeMap(() => from(syncClient.serverHealthy())),
    mergeMap(() =>
      of(
        // actions.resume(),
        actions.reachable(),
        actions.updateUnsyncCountRequest()
      )
    )
  );

export const updateCountEpic = (
  action$: Observable<Action>,
  _: StateObservable<RootAppState>,
  { queue }: Dependencies
) =>
  action$.pipe(
    filter(actions.updateUnsyncCountRequest.match),
    mergeMap(() => from(queue.count())),
    map(count => actions.updateUnsyncCountFulfilled(count))
  );

export default combineEpics<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Action<any>,
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any;
    type: string;
  },
  RootAppState
>(popQueueEpic, updateCountEpic, checkServerEpic, clearQueueEpic);
