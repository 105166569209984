import { APIAction } from 'api';
import { UUID } from 'io-ts-types';
import * as R from 'ramda';

import { DB } from './index.ts';
import { QueueOp } from './types.ts';


export default (db: DB) => {
  /**
   * Push into a FIFO queue
   *
   * @param cp ControlPoint to submit changes to
   * @returns
   */
  const push = (id: string, apiOp: APIAction, docId: UUID) =>
    db.queue.put({ key: id, id, apiOp, docId });

  /**
   * Pop from a FIFO queue
   *
   * @returns Head of the queue, runs thunk, and deletes from the queue on success
   */
  const pop = async (thunk: (arg0: unknown) => Promise<boolean>) => {
    await db.transaction('rw', db.doc, db.queue, async () => {
      const s = await db.queue.where('key').startsWith('').first();
      if (!R.isNil(s)) {
        const res = await thunk(s);
        if (res) {
          await db.queue.delete(s.key);
        }
      }
    });
  };

  /**
   * Grab the head of the FIFO Queue
   *
   * @returns Head of the Queue
   */
  const peek = (): Promise<QueueOp | undefined> =>
    db.queue.where('key').startsWith('').first();

  /**
   * Count of unsynced points
   */
  const count = () => db.queue.count();

  const remove = (key: string): Promise<void> =>
    db.transaction('rw', db.queue, async () => await db.queue.delete(key));

  const removeAll = (): Promise<void> =>
    db.transaction('rw', db.queue, async () => await db.queue.clear());

  const getAllWithPrefix = (prefix: string): Promise<QueueOp[]> =>
    db.queue.where('key').startsWith(prefix).toArray();

  /**
   * Get All Unsynced Points
   *
   * @returns Promise of QueueOp[]
   */
  const getAll = () => db.queue.limit(500).toArray();

  return {
    push,
    pop,
    peek,
    count,
    getAll,
    getAllWithPrefix,
    remove,
    removeAll,
  };
};
