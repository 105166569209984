import { ApolloProvider } from '@apollo/client';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';

import App from './App.tsx';
import { client } from './api/index.ts';
import keycloakProvider from './auth/keycloak.ts';
import * as serviceWorkerRegistration from './serviceWorkerRegistration.ts';
import store, { history } from './store.ts';

const webRender = () =>
  ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <App store={store} history={history} />
        </Provider>
      </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );

keycloakProvider.initKeycloak(webRender);

serviceWorkerRegistration.register();
