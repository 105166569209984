import { EnhancedStore } from '@reduxjs/toolkit';
import { filter, from, fromEvent, map, mapTo, merge, mergeMap, of } from 'rxjs';

import { ConnectionState, actions } from './slice.ts';
import { serverHealthy } from '../api/index.ts';

const networkStatu$ = merge(
  fromEvent(window, 'offline').pipe(mapTo(ConnectionState.OFFLINE)),
  fromEvent(window, 'online').pipe(mapTo(ConnectionState.ONLINE)),
  of(window.navigator.onLine ? ConnectionState.ONLINE : ConnectionState.OFFLINE)
);

const offline$ = networkStatu$.pipe(
  filter(st => st === ConnectionState.OFFLINE),
  map(() => actions.offline)
);
const online$ = networkStatu$.pipe(
  filter(st => st === ConnectionState.ONLINE),
  map(() => actions.online)
);

const serverStatus$ = online$.pipe(mergeMap(() => from(serverHealthy())));
const serverUp$ = serverStatus$.pipe(
  filter(reachable => reachable),
  map(() => actions.reachable)
);

const serverDown$ = serverStatus$.pipe(
  filter(reachable => !reachable),
  map(() => actions.unreachable)
);

const startNetworkListener = (store: EnhancedStore) =>
  merge(online$, offline$, serverUp$, serverDown$).subscribe({
    next: action => store.dispatch(action()),
  });

export default startNetworkListener;
