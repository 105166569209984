import {
  AppRegistration as Projects,
  AssignmentTurnedInOutlined as ApprovalQueue,
  CloudUpload,
  ControlPoint,
  Download,
  Public,
  Summarize,
  Sync,
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Tooltip,
  styled,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { AccessLevel, Scope } from 'usmart-common';

import Logo from './usace-logo-color.svg?react';
import AuthButton from '../auth/AuthButton.tsx';
import keycloakProvider from '../auth/keycloak.ts';
import { ROUTE } from '../constants.ts';

type Props = {
  drawerOpen: boolean;
  toggleDrawer: () => void;
  isAuthed?: boolean;
};

const Header = ({ drawerOpen: _do, toggleDrawer: _td, isAuthed = false }: Props) => {
  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        sx={{
          zIndex: theme => theme.zIndex.drawer + 1,
          '@media print': {
            display: 'none',
          },
        }}
      >
        <Toolbar>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            component={Link}
            to={ROUTE.HOME}
            sx={{
              fontSize: 20,
              '& svg': {
                height: 34,
                padding: '0px 10px 0px 0px',
              },
            }}
          >
            <Logo /> U-SMART
          </Button>
          <Spacer />
          <div />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {keycloakProvider.hasAccess(
              Scope.controlPoints,
              AccessLevel.manage,
            ) && (
                <Link to={ROUTE.APPROVAL_QUEUE}>
                  <Tooltip title="Approval Queue">
                    <IconButton sx={{ color: 'white' }}>
                      <ApprovalQueue />
                    </IconButton>
                  </Tooltip>
                </Link>
              )}
            {isAuthed && (
              <React.Fragment>
                <Link to={ROUTE.PROJECTS}>
                  <Tooltip title="Projects">
                    <IconButton sx={{ color: 'white' }}>
                      <Projects />
                    </IconButton>
                  </Tooltip>
                </Link>
                <Link to={ROUTE.BULK_UPLOAD_VIEW}>
                  <Tooltip title="Bulk Upload">
                    <IconButton sx={{ color: 'white' }}>
                      <CloudUpload />
                    </IconButton>
                  </Tooltip>
                </Link>
              </React.Fragment>
            )}
            {keycloakProvider.hasAccess(
              Scope.controlPoints,
              AccessLevel.write
            ) && (
                <React.Fragment>
                  <Link to={ROUTE.POINTS_ADD}>
                    <Tooltip title="Add Control Point">
                      <IconButton sx={{ color: 'white' }}>
                        <ControlPoint />
                      </IconButton>
                    </Tooltip>
                  </Link>
                  <Link to={ROUTE.SYNC}>
                    <Tooltip title="Sync">
                      <IconButton sx={{ color: 'white' }}>
                        <Sync />
                      </IconButton>
                    </Tooltip>
                  </Link>
                </React.Fragment>
              )}
            <Link to={ROUTE.REPORTS_VIEW}>
              <Tooltip title="Reports">
                <IconButton sx={{ color: 'white' }}>
                  <Summarize />
                </IconButton>
              </Tooltip>
            </Link>
            <Link to={ROUTE.CACHE}>
              <Tooltip title="Cached Control Points">
                <IconButton sx={{ color: 'white' }}>
                  <Download />
                </IconButton>
              </Tooltip>
            </Link>
            <Link to={ROUTE.MAP}>
              <Tooltip title="Map">
                <IconButton sx={{ color: 'white' }}>
                  <Public />
                </IconButton>
              </Tooltip>
            </Link>
          </Box>
          <AuthButton />
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;

const Spacer = styled('div')`
  flex-grow: 1;
`;
