import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export enum AuthStatus {
  pending = 'PENDING',
  authenticated = 'AUTHENTICATED',
  anonymous = 'ANONYMOUS',
}

type Auth = {
  isAuthed: AuthStatus;
  tokens: Tokens;
};

type Tokens = {
  idToken: string | undefined;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthed: AuthStatus.pending,
    tokens: {
      idToken: undefined,
    },
  },
  reducers: {
    signedin: (state: Auth) => {
      state.isAuthed = AuthStatus.authenticated;
    },
    signout: (state: Auth) => {
      state.isAuthed = AuthStatus.anonymous;
    },
    setTokens: (state: Auth, action: PayloadAction<Tokens>) => {
      if (action.payload.idToken) state.isAuthed = AuthStatus.authenticated;
      else state.isAuthed = AuthStatus.anonymous;
      state.tokens = action.payload;
    },
    clear: (state: Auth) => {
      state.isAuthed = AuthStatus.pending;
      state.tokens = { idToken: undefined };
    },
  },
});

export const { signedin, signout, setTokens, clear } = authSlice.actions;

export default authSlice.reducer;
