import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { NotificationProps } from './Notification.tsx';

type NotificationState = {
  notifications: Array<NotificationProps>;
  currentNotification?: NotificationProps;
};

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: new Array<NotificationProps>(),
    currentNotification: undefined,
  },
  reducers: {
    clear: (state: NotificationState) => {
      state.notifications = [];
      state.currentNotification = undefined;
    },
    pushNotification: (
      state: NotificationState,
      action: PayloadAction<NotificationProps>
    ) => {
      state.notifications.push(action.payload);
    },
    popNotification: (state: NotificationState) => {
      state.currentNotification = state.notifications.shift();
    },
    clearNotification: (state: NotificationState) => {
      state.currentNotification = undefined;
    },
  },
});

export const { clear, pushNotification, popNotification, clearNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;
